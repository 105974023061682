import React from 'react';

import Layout from '../components/Layout';

import pic1 from '../assets/images/pic01.png';
import pic2 from '../assets/images/pic02.png';
import pic3 from '../assets/images/pic03.png';

import config from '../../config';
const IndexPage = () => (
  <Layout>
    <section id="banner">
      <div className="inner">
        <h2>{config.heading}</h2>
        <p>{config.subHeading}</p>
      </div>
    </section>

    <section id="wrapper">
      <section id="one" className="wrapper spotlight style1">
        <div className="inner">
          <img class="image" src={pic1} alt="" />
          <div className="content">
            <h2 className="major">Interpretation</h2>
            <p>
              Professional consecutive and simultaneous interpretation of events, presentations, meetings, negotiations, interviews and more from Korean to English, English to Korean and Chinese to English. I also provide guided interpretation for visitors to Seoul who are attending events, conducting business meetings, meeting clients or just need help getting around the city.
            </p>
          </div>
        </div>
      </section>

      <section id="two" className="wrapper alt spotlight style2">
        <div className="inner">
          <img class="image" src={pic2} alt="" />
          <div className="content">
            <h2 className="major">Translation</h2>
            <p>
              Professional translation of a wide variety of texts from Korean to English, English to Korean and Chinese to English, including reports, legal documents, marketing materials, research papers, presentations, speeches, product descriptions and more. With a solid understanding of translation theory and a well-tuned sense of language, I can turn even challenging texts into clear, easy-to-read translations.
            </p>
          </div>
        </div>
      </section>

      <section id="three" className="wrapper spotlight style3">
        <div className="inner">
          <img class="image" src={pic3} alt="" />
          <div className="content">
            <h2 className="major">Editing</h2>
            <p>
              Meticulous and accurate proofreading and editing of English texts including speeches, theses, research papers, marketing materials, presentations and more. With years of experience, I understand the common challenges that Chinese and Korean speakers face when writing English and can rewrite texts into natural, smooth English that is intuitive to native speakers.
            </p>
          </div>
        </div>
      </section>

      <section id="four" className="wrapper alt style1">
        <div className="inner">
          <h2 className="major">Projects and clients</h2>
          <section className="features">
            <article>
              <h3 className="major">Future Consensus Institute (Yeosijae)</h3>
              <p>
                Translated research papers, event proposals and reports for the Future Consensus Institute, a non-profit research center that focuses on Northeast Asian issues including regional cooperation and building peace and prosperity.
              </p>
            </article>
            <article>
              <h3 className="major">Unicity Korea</h3>
              <p>
                Translated press releases, product descriptions, letters and other materials for Unicity Korea, the Korean division of Unicity International, a company that aims to promote good health through scientifically developed nutritional supplements, health functional foods and other lifestyle products.
              </p>
            </article>
            <article>
              <h3 className="major">Cityscape Korea</h3>
              <p>
                Interpreted several presentations at Cityscape Korea 2017, Asia’s premier international real estate exhibition where developers, construction firms and investors gather to exchange information on real estate and new development projects.
              </p>
            </article>
            <article>
              <h3 className="major">PEN International North Korean Writers in Exile Centre</h3>
              <p>
                Translated official documents and letters written by North Korean defectors for publications related to the promotion of human rights. PEN International is a London-based worldwide association of writers founded to promote friendship and cooperation and pursue freedom of expression.
              </p>
            </article>
            <article>
              <h3 className="major">Gwangju Universiade 2015</h3>
              <p>
                Worked as an official translator and interpreter at the 2015 Summer Universiade in Gwangju, facilitating communication between members of the Organizing Committee, technical officials and athletes. The Universiade is an international multi-sport event, organized for university athletes by the International University Sports Federation.
              </p>
            </article>
            <article>
              <h3 className="major">Human Asia</h3>
              <p>
                Translated memoirs written by North Korean defectors for publications related to the promotion of human rights. Human Asia is a non-profit organization based in Seoul that carries out human rights advocacy activities and campaigns in Asia.
              </p>
            </article>
            <article>
              <h3 className="major">Bucheon City Library</h3>
              <p>
                Translated and edited online information, book reviews, library programs and other materials about the city of Bucheon and its history. Bucheon is a city with numerous libraries and a vibrant literary culture, and was recently designated as a UNESCO City of Literature.
              </p>
            </article>
            <article>
              <h3 className="major">Journal of Ethnic Foods</h3>
              <p>
                Translated research papers, articles and editorials for the Journal of Ethnic Foods, a journal that is dedicated to providing reliable information about various foods and the value of traditional food from a perspective that includes culture, ecology, history and the environment.
              </p>
            </article>
          </section>
          <ul className="actions">
            <li>
              <a className="button disabled">
                See more
              </a>
            </li>
          </ul>
        </div>
      </section>
    </section>
  </Layout>
);

export default IndexPage;
